
import api from '@/services/api'
import { defineComponent, onMounted, ref, nextTick } from 'vue'
import { Group, GroupRequest } from '@/types/Groups'
import { useRouter } from 'vue-router'
import { toastError } from '@/helpers/error-handling'
import { FileUploadSelectEvent } from 'primevue/fileupload'
import imageValidator from '@/classes/ImageValidatorClass'
import { AxiosResponse } from 'axios'
import { useToast } from 'primevue/usetoast'

export default defineComponent({
  props: ['id'],
  components: {},
  setup(props) {
    const router = useRouter()
    const toast = useToast()
    const validator = new imageValidator()
    
    const userStatus = ref<string>('active')

    const group = ref<Group>({} as Group)
    const groups = ref<Group[]>([])
    const isPrefixSet = ref<boolean>(true)
    const imageFile = ref()
    const previewImage = ref('')
    const uploadReady = ref(true)

    onMounted(() => {
      fetchData()
    })

    const fetchData = async () => {
      try {
        const { data } = await api.get(`manager/group/${props.id}`)
        group.value = data.data
        isPrefixSet.value = group.value?.initials?.length > 0
      } catch (err) {
        toastError(toast, err)
      }
    }

    const handleSubmit = () => {
      const groupUpdate: GroupRequest = {
        externalId: group.value.externalId,
        name: group.value.name,
        color: group.value.color,
        infoScreenText: group.value.infoScreenText,
        initials: isPrefixSet.value ? null : group.value.initials,
        blendedCareAvailable: group.value.blendedCareAvailable,
        infoScreenAvailable: group.value.infoScreenAvailable,
        gamesAvailable: group.value.gamesAvailable
      }
      
      api.put('manager/group', JSON.stringify(groupUpdate))
        .then((res: AxiosResponse) => {
          const groupResponse: Group = res.data.data
          if(imageFile.value) {
            const formData = new FormData()
            formData.append("file", imageFile.value)
            api.put(`manager/group/upload/${groupResponse.externalId}`, formData)
            .then(() => {
              router.push('/groups')
            })
            .catch((err: any) => console.log('fileupload error', err))
          } else { 
            router.push('/groups')
          }
        })
        .catch((err) => toastError(toast, err))
    }

    const resetUpload = async () => {
      uploadReady.value = false
      await nextTick()
      uploadReady.value = true
    }

    const onFileSelect = (event: FileUploadSelectEvent) => {
      const file = event.files[0]
      if (!file) return

      imageFile.value = file
      const reader = new FileReader()
      reader.onload = () => {
        const image = new Image()
        image.onload = async () => {
          if (validator.validateImage(image, file)) {
            previewImage.value = reader.result as string
          } else {
            imageFile.value = null
            await resetUpload()
            toast.add({ severity: 'error', summary: 'Invalid image dimensions or size.', life: 3000})
          }
        }
        image.src = reader.result as string
      }
      reader.readAsDataURL(file)
    }

    const clearPreview = () => {
      previewImage.value = ''
    }

    const deleteImage = () => {
      api.delete(`manager/group/image/${group.value.externalId}`)
        .then(() => fetchData())
        .catch(err => console.log(err.message))
    }

    return { 
      group,
      userStatus,
      groups,
      isPrefixSet,
      previewImage,
      uploadReady,
      handleSubmit,
      onFileSelect,
      clearPreview,
      deleteImage
    }
  }
})
